import * as React from "react"

import Article from "../../route/article_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Article props={props} />
      </React.Fragment>
  )
}

export default IndexPage
