import React, { Component } from "react";


import Layout from "../components/layou_en"


import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'
import { navigate } from "gatsby"

import Moment from "moment";
import en from "moment/locale/en-in";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";



Moment.locale("en", en);
const objectGraphql = graphql`
{
  allStrapiNoticias(filter: {locale: {eq: "en"}}) {
    edges {
      node {
          id
        titulo
         
        Tags {
          nombre
          id
        }
        Categorys {
         nombre
       }
        fecha
        min_descripcion
        descripcion
        imagen {
        url
      
        }
        Categorys {
          nombre
        }
      }
    }
  }
  allStrapiHome(filter: {locale: {eq: "en"}}) {
    edges {
      node {
        id
        Cantidad_noticias
        Frases {
          id
          descripcion
          titulo
        }
      } 
        
    }  
       
  }
}
`



class Article extends Component {
  constructor(props) {
    super(props);
    this.state = { search: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    console.log(event.target.value);
    this.setState({ search: event.target.value });
  }
  handleSubmit(event) {
    navigate(
      "/en/search",
      {
        state: { search: this.state.search }
      }
    )
    event.preventDefault();
  }
  toUniqueArray = (item) => {
    let newArr = [];
    for (let i = 0; i < item.length; i++) {
      if (newArr.indexOf(item[i]) === -1) {
        newArr.push(item[i]);
      }
    }
    return newArr;
  }
  alls = (data, filter) => {
    const array = data.allStrapiNoticias.edges;
    let filtertags = []

    array.forEach(element => {
      if (Array.isArray(element.node[filter])) {
        console.log(Array.isArray(element.node[filter]));

        element.node[filter].forEach(item => {
          filtertags.push(item.nombre)
        });
      } else {
        filtertags.push(element.node[filter].nombre)
      }

    });
    let tagUnique = this.toUniqueArray(filtertags);
    return (
      tagUnique
    )

  }
  category = (item) => {
    let text = "";
    text = item;

    if (item === "sustainability") {
      text = "Sustainability";
    } if (item === "news") {
      text = "News";
    } if (item === "humanTalent") {
      text = "Human talent";
    } if (item === "business") {
      text = "Business";
    }
    return text
  }
  news = (data) => {
    let array = data.allStrapiNoticias.edges;
    let sortedArray = array.sort((a, b) => new Moment(a.node.fecha).format('YYYYMMDD') - new Moment(b.node.fecha).format('YYYYMMDD'))
    let aNuevo = (sortedArray.slice(sortedArray.length - (data.allStrapiHome.edges[0].node.Cantidad_noticias))).reverse()


    return aNuevo.map((item, index) => {
      return (

        <div key={index} className="row thumb-notas">
          <div className="col-sm-4 col-md-3 col-lg-5">
            <p>
              <a style={{ display: "flex", width: "100%", height: "168px", margin: "auto" }} href={`/en/news/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} title="">
                <img style={{ objectFit: "cover" }} src={item.node.imagen[0].url} alt={item.node.imagen[0].url} className="img-fluid" />
              </a>
            </p>
          </div>
          <div className="col-sm-8 col-md-9 col-lg-7">
            <p className="noticia-datos">
              {Moment(item.node.fecha).format(
                "D"
              )}     {Moment(item.node.fecha).format(
                "MMMM"
              )}

              <a href={`/en/category/${item.node.Categorys.nombre.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}> {this.category(item.node.Categorys.nombre)}</a>                    </p>
            <p>
              <a href={`/en/news/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>  {item.node.titulo}  </a>
            </p>
          </div>
        </div>
      )
    })
  }
  render() {

    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout imgUrl={this.props.props.pageContext.imagen[0].url} traduction={false} nameTab={this.props.props.pageContext.titulo} props={this.props.props}>

              <section className="titulo-int-noticia">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2>New</h2>
                    </div>
                    <div className="col-md-9 text-right b-none">
                      <ol className="breadcrumb">

                        <li className="breadcrumb-item"><a href="/en">Home</a></li>
                        <li className="breadcrumb-item"><a href={`/en/category/${this.props.props.pageContext.Categorys.nombre.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} > {this.category(this.props.props.pageContext.Categorys.nombre)}</a></li>
                        <li className="breadcrumb-item active">{this.props.props.pageContext.titulo}</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="padding-section-int-not">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-md-12">

                          <p><img src={this.props.props.pageContext.imagen[0].url} alt={this.props.props.pageContext.imagen[0].url} className="img-fluid" /></p>
                        </div>
                        <div className="col-md-2 text-center">
                          <ul className="nota-social">

                            <li><i className="fa fa-share-alt"  ></i></li>

                            <li>
                              <FacebookShareButton className='redes fac'
                                 
                                url={this.props.props.location.href}
                              >
                                <div className='redes fac' >
                                  <i className="fa fa-facebook"  ></i>
                                </div>
                              </FacebookShareButton>
                            </li>

                            <li>
                              <TwitterShareButton className='redes twi'
                                title={this.props.props.pageContext.titulo}
                                url={this.props.props.location.href}
                              >
                                <div className='redes twi' >
                                  <i className="fa fa-twitter"  ></i>
                                </div>
                              </TwitterShareButton>
                            </li>

                            <li>
                              <LinkedinShareButton className='redes lin'
                                title={this.props.props.pageContext.titulo}
                                summary={this.props.props.pageContext.min_descripcion}
                                url={this.props.props.location.href}
                              >
                                <div className='redes lin' >
                                  <i className="fa fa-linkedin"  ></i>
                                </div>
                              </LinkedinShareButton>
                            </li>


                          </ul>
                        </div>
                        <div className="col-md-10 noticia-info">
                          <p className="noticia-datos">
                            {Moment(this.props.props.pageContext.fecha).format(
                              "D"
                            )}  {Moment(this.props.props.pageContext.fecha).format(
                              "MMMM"
                            )} /
                            <a href={`/en/category/${this.props.props.pageContext.Categorys.nombre.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} > {this.category(this.props.props.pageContext.Categorys.nombre)}</a>            </p>
                          <h3>{this.props.props.pageContext.titulo}</h3>
                          <ReactMarkdown>{this.props.props.pageContext.descripcion}</ReactMarkdown>
                        </div>

                        <div className="col-md-10 offset-md-2 tags">
                          <p>
                            <span><i className="fa fa-tag"  ></i> TAGS  </span>
                            {this.props.props.pageContext.Tags.map((item, index) => {

                              return (
                                <a key={index} style={{ textTransform: 'capitalize' }}   href={`/en/tag/${item.nombre.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>  {item.nombre}  </a>
                              )
                            })}
                          </p>
                        </div>

                        <div className="col-md-10 offset-md-2 compartir-int">

                          <div className="row">
                            <div className="col-md-12 text-center">
                              <h5>Share</h5>
                            </div>
                            <div className="col-4 ">
                              <p className="redes-not">
                                <FacebookShareButton className='redes fac full-w'
                                   
                                  url={this.props.props.location.href}
                                >
                                  <div className='redes fac full-w' >
                                    <i className="fa fa-facebook"  ></i>
                                  </div>
                                </FacebookShareButton>
                              </p>
                            </div>
                            <div className="col-4 ">
                              <p className="redes-not">
                                <TwitterShareButton className='redes twi full-w'
                                  title={this.props.props.pageContext.titulo}
                                  url={this.props.props.location.href}
                                >
                                  <div className='redes twi full-w' >
                                    <i className="fa fa-twitter"  ></i>
                                  </div>
                                </TwitterShareButton>
                              </p>
                            </div>
                            <div className="col-4 ">
                              <p className="redes-not">
                                <LinkedinShareButton className='redes lin full-w'
                                  title={this.props.props.pageContext.titulo}
                                  summary={this.props.props.pageContext.min_descripcion}
                                  url={this.props.props.location.href}
                                >
                                  <div className='redes lin full-w' >
                                    <i className="fa fa-linkedin"  ></i>
                                  </div>
                                </LinkedinShareButton>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 notas-lateral">
                      <div id="block-custom-search-blocks-2">
                        <h4>Search</h4>
                        <form onSubmit={this.handleSubmit} className="form-inline buscador" id="custom-search-blocks-form-2" accept-charset="UTF-8" target="_self">
                          <input type="text" defaultValue={this.state.search} onChange={this.handleChange} title="Ingresa el texto a buscar" className="form-control" placeholder="Ingresa el texto a buscar" type="text" id="edit-custom-search-blocks-form-2--2" name="custom_search_blocks_form_2" size="15" maxlength="128" />
                          <input className="form-submit btn btn-primary" type="submit" value="S" />
                        </form>
                      </div>

                      <div className="view-header">
                        <h4 className="titulo-lateral">
                          Recent articles
                        </h4>
                      </div>
                      {

                        this.news(data)
                      }
                      <div className="view view-taxonomy-block view-id-taxonomy_block view-display-id-block lista-categorias view-dom-id-d977defd9c1118171b57cc3034525c78">
                        <div className="view-header">
                          <h4 className="titulo-lateral">
                            Categories
                          </h4>
                        </div>
                        <ul className="categorias-list">
                          {this.alls(data, 'Categorys').map((item, index) => {
                            return (
                              <li className="">
                                <a key={index} href={`/en/category/${item.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>{item}</a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <div className="view view-taxonomy-block view-id-taxonomy_block view-display-id-block_1 nube-tags view-dom-id-4821b2be546855c87b6abcd273ec3f3b">
                        <div className="view-header">
                          <h4 className="titulo-lateral">
                            Tag cloud
                          </h4>
                        </div>
                        {this.alls(data, 'Tags').map((item, index) => {
                          return (
                            <a key={index} href={`/en/tag/${item.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>{item}</a>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default Article
